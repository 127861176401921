import React, { forwardRef } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { invalidInputStyle } from "~styles/common/a11y.error.css";
import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { InputErrorMessage } from "~components/InputErrorMessage";
import { Label } from "~components/Label";

import * as styles from "./styles.css";

import type { InputVariants, VariantInputAppearanceEnum } from "./styles.css";
import type { AriaRole, LegacyRef, ReactNode } from "react";
import type { IconFontAwesomeProps } from "~components/IconFontAwesome";
import type { VariantUiSizeEnum } from "~styles/common/variantUiScale.css";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

interface InputRequiredProps {
  name: string;
}

type InputHasLabelConditionalProps =
  | {
      id: string;
      label: string;
    }
  | {
      id?: string;
      label?: undefined;
    };

interface InputOptionalProps
  extends GetSprinklesArgs,
    Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      "size" | "color" | "height" | "width" | "id"
    > {
  appearance?: VariantInputAppearanceEnum;
  decorativeNodeLeft?: ReactNode;
  decorativeNodeRight?: ReactNode;
  errorMessage?: string;
  iconLeft?: IconFontAwesomeProps["icon"];
  iconRight?: IconFontAwesomeProps["icon"];
  invalid?: boolean;
  placeholder?: string;
  role?: AriaRole;
  size?: VariantUiSizeEnum;
  value?: string;
  variants?: InputVariants;
}

export type InputProps = InputRequiredProps &
  InputHasLabelConditionalProps &
  InputOptionalProps;

/** Renders an accessible input component. */
export const Input = forwardRef(
  (
    {
      autoComplete,
      iconLeft,
      iconRight,
      disabled,
      required,
      className: userClassName,
      id,
      name,
      onChange,
      placeholder,
      label,
      invalid,
      errorMessage,
      role,
      decorativeNodeLeft,
      decorativeNodeRight,
      type,
      value,
      variants,
      size = "md",
      appearance = "default",
      ...rest
    }: InputProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

    const inputWrapperStyles = clsx(
      styles.getInputClassNames({ ...variants, size, appearance }),
      {
        [invalidInputStyle]: invalid, // Add invalid style when invalid = true
        [styles.offsetIconLeft]:
          (!!iconLeft || !!decorativeNodeLeft) && (placeholder || value), // Offset input text if icon present and text exists
        [styles.offsetIconRight]: !!iconRight || !!decorativeNodeRight, // Offset input text if icon present,
      }
    );

    const placeholderSize = (placeholder?.length ?? 1) - 1;

    return (
      <Box position="relative" className={userClassName} {...atomProps}>
        {label && id && <Label htmlFor={id} label={label} />}
        {decorativeNodeLeft && (
          <Box className={styles.iconLeft}>{decorativeNodeLeft}</Box>
        )}
        {!decorativeNodeLeft && iconLeft && (
          <IconFontAwesome
            className={styles.iconLeft}
            color="text_lowContrast"
            icon={iconLeft}
            size="sm"
            data-testid="icon-leading"
            aria-hidden
          />
        )}
        <input
          /** Common attributes */
          id={id}
          className={inputWrapperStyles}
          ref={ref}
          /** Input attributes */
          autoComplete={autoComplete}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          role={role}
          type={type}
          value={value}
          /** Disabled  */
          aria-disabled={disabled}
          disabled={disabled}
          /** Required */
          aria-required={required}
          required={required}
          {...otherProps}
          size={value?.length || placeholderSize || 1}
        />
        {decorativeNodeRight}
        {!decorativeNodeRight && iconRight && (
          <IconFontAwesome
            aria-hidden
            className={styles.iconRight}
            data-testid="icon-trailing"
            icon={iconRight}
            size="sm"
          />
        )}
        {invalid && (
          <InputErrorMessage
            message={errorMessage || "Invalid input, please try something else"}
          />
        )}
      </Box>
    );
  }
);
