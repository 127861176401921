import React from "react";

import { useResponsiveFieldValue } from "~styles/css_runtime_utils/useResponsiveFieldValue";

import { Box } from "../Box";

import type { BoxProps } from "../Box";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export function Grid({
  children,
  display = "grid",
  gap = "gutterWidth",
  gridTemplateColumns = 12,

  ...rest
}: BoxProps) {
  const validGridTemplateColumns =
    useResponsiveFieldValue<GetSprinklesArgs["gridTemplateColumns"]>(
      gridTemplateColumns
    );

  return (
    <Box
      display={display}
      gap={gap}
      gridTemplateColumns={validGridTemplateColumns}
      {...rest}
    >
      {children}
    </Box>
  );
}
