import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import { FontAwesomeIcon as VendorIconFontAwesome } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import type { FontAwesomeIconProps as VendorFontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export interface IconFontAwesomeProps
  extends Pick<
      VendorFontAwesomeIconProps,
      "icon" | "spin" | "flip" | "size" | "className"
    >,
    GetSprinklesArgs {}

export function IconFontAwesome({
  icon,
  className: userClassName,
  ...rest
}: IconFontAwesomeProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  return (
    <VendorIconFontAwesome
      icon={icon}
      className={clsx(userClassName, getSprinkles({ ...atomProps }))}
      {...otherProps}
    />
  );
}
