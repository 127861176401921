import React from "react";

import { Box } from "../Box";
import { getHorizontalDividerStyles } from "./styles.css";

import type { HorizontalDividerVariants } from "./styles.css";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export interface HorizontalDividerProps extends GetSprinklesArgs {
  text?: string;
  variants?: HorizontalDividerVariants;
}
export function HorizontalDivider({
  text,
  variants,
  ...rest
}: HorizontalDividerProps) {
  return (
    <Box className={getHorizontalDividerStyles(variants)} {...rest}>
      {text && <Box textAppearance="prefix_lg">{text}</Box>}
    </Box>
  );
}
