import React from "react";

import { faCircleExclamation } from "@fortawesome/sharp-regular-svg-icons/faCircleExclamation";

import { Box } from "../Box";
import { IconFontAwesome } from "../IconFontAwesome";
import * as styles from "./styles.css";

export interface InputErrorMessageProps {
  message?: string;
}

export function InputErrorMessage({ message }: InputErrorMessageProps) {
  if (message) {
    return (
      <Box className={styles.inputErrorMessage}>
        <IconFontAwesome icon={faCircleExclamation} size="xs" />
        <div>{message}</div>
      </Box>
    );
  }

  return null;
}
