import React, { useMemo } from "react";

import { parseStoryblokEntry } from "~utils/storyblok/parseStoryblokEntry";

import { SeoMetadata } from "~components/SeoMetadata";

import type { HeadProps as GatsbyHeadProps } from "gatsby";
import type { GlobalPageData } from "~types/global.types";
import type {
  CommonPageContext,
  StoryblokEntryWithStringifiedContent,
} from "~types/storyblok.types";

export interface HeadProps extends GatsbyHeadProps {
  data: GlobalPageData;
  pageContext: CommonPageContext;
}

export function Head({ location, data, pageContext }: HeadProps) {
  const { alternates, metaImage } =
    (data.story as StoryblokEntryWithStringifiedContent) || {};

  const { lang } = pageContext || {};

  const metadata = useMemo(() => {
    if (data.story) {
      const story = parseStoryblokEntry(
        "story",
        data.story as StoryblokEntryWithStringifiedContent
      );
      const { pageMetadata } = story || {};

      return pageMetadata;
    }

    return undefined;
  }, [data.story]);

  return (
    <>
      {/**
       * ------------------------------------------------------------------------------
       * Site verification for various analytics tools
       * ------------------------------------------------------------------------------
       */}

      <meta
        name="google-site-verification"
        content="sfc6MxT2PK6jfrLkaNCMK4iwWTrjSlCrxCbNQR1vYZo"
      />
      <meta
        name="ahrefs-site-verification"
        content="bcb32992a4a49651059dd10ed8108acbfeb551ef607c4c5928b94e10bf709ea8"
      />
      <meta
        name="facebook-domain-verification"
        content="40hh9o9svwgfafsb29gx4b0i3vnz2s"
      />

      {/**
       * ------------------------------------------------------------------------------
       * Seo metadata
       * ------------------------------------------------------------------------------
       */}

      <SeoMetadata
        {...metadata}
        metaImage={metaImage}
        alternates={alternates}
        fullSlug={location.pathname}
        language={lang}
      />
    </>
  );
}
