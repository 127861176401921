import React from "react";

import clsx from "clsx";

import { global_backgroundImage } from "~styles/common/global_backgroundImage.css";

import { Image } from "../Image";

import type { BoxProps } from "~components/Box";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface ImageBackgroundProps
  extends GetSprinklesArgs,
    StoryblokBlok,
    BoxProps {
  className?: string;
  image: StoryblokFieldMedia;
  objectFit?: GetSprinklesArgs["objectFit"];
}

/**
 * Renders an image that expands to fill the height and width
 * of the nearest parent container with relative positioning.
 */
export function ImageBackground({
  image,
  className: userClassName,
  objectFit = "cover",
  ...rest
}: ImageBackgroundProps) {
  const classNames = clsx(userClassName, global_backgroundImage);

  if (!image) return null;

  return (
    <div className={classNames}>
      <Image
        image={image}
        objectFit={objectFit}
        width="100%"
        height="100%"
        {...rest}
      />
    </div>
  );
}
