import React from "react";

import { Box } from "~components/Box";

export interface LabelProps {
  label: string;
  htmlFor: string;
  className?: string;
}

export function Label({ label, htmlFor, className }: LabelProps) {
  return (
    <Box className={className} as="label" htmlFor={htmlFor}>
      {label}
    </Box>
  );
}
