import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import type { ALL_ICONS } from "./ALL_ICONS.mock";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export type IconBrandEnum = (typeof ALL_ICONS)[number];

export interface IconBrandProps extends GetSprinklesArgs {
  icon?: IconBrandEnum | null;
  className?: string;
}

export function IconBrand({
  icon,
  className: userClassName,
  color = "accent_base",
  ...rest
}: IconBrandProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  if (!icon) return null;

  const iconClassNames = clsx(
    getSprinkles({
      width: "spacing5",
      height: "spacing5",
      color,
      ...atomProps,
    }),
    userClassName
  );

  return (
    <svg
      viewBox="0 0 48 48"
      role="img"
      className={iconClassNames}
      {...otherProps}
    >
      <use xlinkHref={`/images/icons/${icon}.svg#icon`} />
    </svg>
  );
}
